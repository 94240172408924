@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */

 $color-orange: #D07171;
 $color-brown: rgb(130, 20, 40);
 $color-red: #D21E22;
 $color-base: #444;
 $color-light-brown: #8b2430;


body {
  margin: 0;
  padding: 0;
  color: $color-base;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 400;
  background: #FBF6EB;
  @media (max-width: 919px){
    &.sp-menu-open{
      overflow: hidden;
    }
  }
}
h1,h2 {
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-feature-settings : "palt";
}
h3,h4 {
  font-weight: 400;
}
table{margin: 1em 0;width: 100%;font-size:110%;}
th,td{vertical-align: top;padding:0.4em;border: 1px dotted #5D3F37;}
th{text-align: left;}
td ol, td ul{margin: 0;}

dt{font-weight: bold;}
dd{margin: 0 0 1em;}

a{
  color: $color-base;
  &:hover{
    color: $color-base;
  }
}
.btn-primary{
  background:$color-base;
  border-color: $color-base;
  padding:.6rem 1.2rem;
  font-size: .9rem;
  border-radius: 8px;
  font-weight: normal;
  transition: 0.5s;
  &:hover{
    background: $color-brown;
    border-color: $color-brown;
  }
}
.kerning{
  font-feature-settings : "palt";
}

/* ---------------------------
header
*/
header{
  p{
    font-size: .8rem;
    padding-top: .4rem;
    margin-bottom: 0;
  }
  .container{
    position: relative;
    background: url('/_assets/img/character.png') no-repeat 15px 3px;
    padding-left: 95px;
  }
  .title{
    margin-bottom: 14px;
    a{
      color: $color-base;
      &:hover{
        text-decoration: none;
      }
    }
  }
  @media (min-width: 992px){
    position: fixed;
    width: 100%;
    top:0;
    z-index: 1200;
    background: rgba(255,255,255,.95);
    .container{
      height: 87px;
      max-width: 1140px;
    }
    .header-wrap{
      display: flex;
      justify-content:space-between;
      align-items: flex-end;
      margin-top: 5px;
    }
    .title{
      margin-bottom: 0;
      a{
        font-size: 1.6rem;
        font-weight: normal;
      }
      img{
        width: 200px;
      }
    }
    ul{
      display: flex;
      justify-content:flex-end;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      font-feature-settings : "palt";
      li{
        padding:1rem .4rem 0 .5rem;
        a, &>span{
          padding: .2rem 0;
          text-decoration: none;
          display: block;
          // border-bottom: 2px solid transparent;
          transition: 0.2s;
          cursor: pointer;
          font-weight: bold;
          &:hover{
            text-decoration: none;
            color: #666;
            // border-bottom-color: #eee;
          }
        }
        &#nav-location{
          &>span{
            vertical-align: center;
            &::after{
              font-family: "Font Awesome 5 Free";
              padding-left: .3rem;
              content: '\f0d7';
              font-weight: 900;
            }
          }
        }
        &#nav-order{
          position: absolute;
          top:-3px;
          right:15px;
          a{
            background: $color-red;
            border-radius: 10px;
            color: #fff;
            border-bottom: 0;
            padding:2px 10px;
            border:2px solid $color-red;
            &:hover{
              background: #fff;
              color: $color-red;
            }
          }
        }
        .pulldown{
          display: none;
          background: #fff;
          position: absolute;
          z-index: 9000 !important;
          box-sizing:border-box;
          box-shadow: 0 2px 2px rgba(0,0,0,0.2);
          width: 31rem;
          max-width: 800px;
          // padding: 10px;
          margin:7px auto 0;
          ul{
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;
            border: 0;
            display: block;
            li{
              color:#000;
              padding: 0.2em 0 0;
              line-height: 1.4;
              display: block;
              text-align: left;
              a {
                display: block;
                padding: .5rem;
                color: $color-base;
                &:hover {
                  color: $color-base;
                  border-bottom-color: transparent;
                  background: #eee;
                }
                strong{
                  font-size: 1rem;
                  padding-left: .2rem;
                }
                span{
                  font-size: .8rem;
                  padding-left: .4rem;
                  display: inline-block;
                }
              }
            }
          }
        }
        .pulldown.spread {
          display: block;
        }
      }
    }
    .overlay, .menu-trigger{
      display: none;
    }
    @media (max-width: 1050px){
      nav > ul >li{
        font-size: .85rem;
        padding: 1rem .3rem 0;
      }
    }
  }
  @media (max-width: 991px){
    background: rgba(255,255,255,0.6);
    position: relative;
    .container{
      max-width: none;
      background-size: 65px;
      padding-left: 90px;
    }
    .title{
      padding: 0 0 5px;
      z-index: -1;
      margin-bottom: 0;
      .logo{
        font-size: 2rem;
      }
      div{
        font-size: .7rem;
        strong{
          font-size: 1rem;
        }
      }
    }
		.menu-trigger {
			display: inline-block;
			width: 28px;
			height: 34px;
			vertical-align: middle;
			cursor: pointer;
			position: absolute;
			top: 25px;
			right: 18px;
			z-index: 2002;
			transform: translateX(0);
			span {
				display: inline-block;
				box-sizing: border-box;
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-base;
				transition: all .5s;
				&:nth-of-type(1) {
					top: 3px;
				}
				&:nth-of-type(2) {
					top: 9px;
				}
				&:nth-of-type(3) {
					top: 15px;
				}
			}
      div{
        font-size: 10px;
        text-align: center;
        bottom: 0;
				position: absolute;
        letter-spacing: -.04rem;
      }
			&.active {
				transform: translateX(0);
				z-index: 3000;
				span {
					background-color: $color-base !important;
					&:nth-of-type(1) {
						transform: translateY(6px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translateY(-6px) rotate(45deg);
					}
				}
			}
		}
    nav{
      display: none;
	  	width: 100vw;
	    height: 100%;
      position: fixed;
	    left: 0;
	    top: 0;
			z-index: 1600;
	    transform: translate(-100vw);
	    transition: all .5s;
	    overflow-y:scroll !important;
	    overflow-x:hidden !important;
      padding: 65px 8px 0;
      ul {
  	    margin: 0 0 5px;
        padding-left: 0;
  			li {
  	      a {
  	        color: $color-base;
  	        display: block;
  	        text-decoration: none;
    				padding: .5rem;
            border-bottom: 1px solid transparent;
            &:hover{
              text-decoration: none;
              color: #000;
            }
  	      }
          &> span{
    				padding: .5rem .6rem .2rem;
            display: block;
          }
          ul{
            margin:0 0 .1rem;
            li{
              line-height: 1.4;
              a{
                padding: .4rem 1.2rem;
                // &::before{
                //   content: '- ';
                // }
              }
              span{
                font-size: .8rem;
              }
              strong{
                padding:0 .4rem 0 .2rem;
                &+span{
                  // color: #999;
                  background: #eee;
                    padding: .1rem .2rem;
                }
              }
            }
          }
          &#nav-order{
            a{
              background: $color-red;
              border-radius: 10px;
              color: #fff;
              border-bottom: 0;
              padding:2px 10px;
              border:2px solid $color-red;
    	        display: inline-block;
              margin-top: .2rem;
              &:hover{
                background: #fff;
                color: $color-red;
              }
            }
          }
  	    }
      }
	    &.open {
        display: block;
        transform: translateX(0);
        overflow:hidden;
        ul{
  	      box-sizing:border-box;
  		    li {
            list-style: none;
  				}
  	    }
  		}
    }
    .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background: rgba(255,255,255,.9);
	    position: fixed;
	    top: 0;
	    left: 0;
	    opacity: 0;
	    transition: opacity .5s;
	    top: 0;
      z-index: 0;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
  	    z-index: 1001;
	    }
	  }

  }
  @media (max-width: 620px){
    p{
      font-size: .6rem;
      padding:5px 0 5px 10px;
    }
    .container{
      background-size: 45px;
      padding-left: 0;
      background-position: 10px 25px;
      .title{
        .logo{
          font-size: 2rem;
          img{
            width: 160px;
          }
        }
      }
      .header-wrap{
        padding-left: 60px;
      }
    }
    nav{
      padding: 80px 8px 0;
    }
    .menu-trigger {
      top: 37px;
    }
  }
  @media (max-width: 374px){
    .container{
      background-position: 5px 40px;
    }
    .menu-trigger {
      top: 50px;
    }
  }
}

/* ---------------------------
footer
*/
footer {
  text-align: center;
  color: #666;
  #footer-wrap{
    padding: 20px 0 40px;
    position: relative;
    .copyright {
      opacity: 0.6;
      font-size: 1rem;
      margin-top: 1rem;
    }
    @media (max-width: 767px){
      padding-bottom: 25px;
      .copyright {
        font-size: .8rem;
      }
    }
  }

}
#page-top {
  position: fixed;
  display: none !important;
  @media (min-width: 768px){
    bottom: 20px;
    z-index: 3000;
  }
  @media (max-width: 767px){
    bottom: 10px;
  }
  right: 10px;
  a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: $color-base;
    text-decoration: none;
    font-size: 10px;
    border-radius:50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    span{
      display: block;
    }
  }
}



/* ---------------------------
contents
*/
#contents{
  h1{
    font-size: 1.8rem;
    margin-bottom: 20px;
    span{
      font-size: 70%;
    }
  }
  p{
    font-size: 1rem;
    line-height: 1.8;
    letter-spacing: 0.1rem;
  }
  p.lead {
    color: #333;
    line-height: 1.5;
  }
  h2 {
    font-size: 1.3rem;
    margin-top: 0.4rem;
  }
  h3{
    font-size: 1.2rem;
    font-feature-settings : "palt";
  }
  @media (min-width: 992px){
    padding: 87px 0 30px;
  }
  @media (max-width: 767px){
    h2{
      font-size: 1.4rem;
    }
  }
  img{
    display: inline-block;
  }
  .content-nav{
    a{
      background: #f9f9f9;
      padding: .5rem;
      border-radius: 3px;
      display: block;
      margin-bottom: 1rem;
      &:hover{
        text-decoration: none;
        background: #f5f5f5;
      }
    }
  }
  .copy{
    font-size: 1.4rem;
  }
  .btn-primary{
    font-weight: 300;
  }
  .btn{
    font-size: 1.2rem;
    font-weight: 300;
    padding: .75rem 2rem;
    border: 2px solid $color-base;
    &:hover{
      background: #fff;
      color: $color-base;
    }
  }
  #information{
    dl{
      border-top: 2px solid #222;
      border-bottom: 2px solid #222;
      padding: 1.4rem 0 .6rem;
      dt{
        font-size: 1.2rem;
      }
      dd{
        font-size: 1.2rem;
        margin-bottom: .8rem;
        a{
          text-decoration: underline;
          font-size: .95rem;
          display: inline-block;
        }
      }
      @media (min-width: 768px){
        dt{
          display: inline-block;
          width: 9rem;
          vertical-align: top;
          padding-bottom: .5rem;
          margin-bottom: 0;
        }
        dd{
          display: inline-block;
          width: calc(100% - 10rem);
          vertical-align: top;
          padding-bottom: .5rem;
          margin-bottom: 0;
        }
      }
      @media (max-width: 520px){
        dt{
          font-size: 1rem;
        }
        dd{
          font-size: 1rem;
        }
      }
    }
  }
}
.dark #contents{
  background: #320505 url('/_uploads/dark-bg.png') repeat-x top center;
  color: #fff;
  a{
    color: #fff !important;
    &:hover{
      color: #fff;
    }
  }
  @media (min-width: 992px){
    padding-top: 0;
    margin-top: 87px;
  }
  .text-red{
    color: #f66;
    font-size:1.5rem;
    font-weight:bold;
  }
  #information{
    p.lead{
      color: #fff;
    }
    dl{
      border-color: #ccc;
      dd a{
        color: #ccc;
      }
    }
  }
}
#headline{
  .directory{
    display: none;
    background: url('/_uploads/header-bg.jpg') no-repeat center center /cover;
    font-size: 32px;
    color: #fff;
    text-align: center;
    padding: 40px;
    letter-spacing: .1rem;
    span{
      font-size: 24px;
      padding:.1rem;
    }
  }
  h1{
    padding:34px 0;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    a{
      color: $color-base;
      text-decoration: none;
    }
  }
  .localnav{
    margin-bottom: 30px;
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;
      li{
        list-style: none;
        padding:20px 10px;
        a{
          padding:.7rem 1.4rem;
          background: #fff;
          border-radius: .8rem;
          border: 3px solid $color-orange;
          &:hover{
            text-decoration: none;
            background: #ffc;
          }
        }
        @media (max-width: 767px){
          font-size: .8rem;
          padding:20px 5px 10px;
          a{
            padding:.5rem 1rem;
          }
        }
      }
    }
  }
  @media (max-width: 767px){
    h1{
      padding:24px 0;
      font-size: 44px;
    }
  }
  @media (max-width: 520px){
    h1{
      padding:20px 0;
      font-size: 34px;
      span{
        font-size: 50%;
      }
    }
  }
}


/* ---------------------------
ホーム
*/
.home{
  header .title{
    display: block;
    h1{
      margin-bottom: 0;
      line-height: 1;
      font-size: 2rem;
    }
  }
  #contents{
    z-index: 1000 !important;
    position: relative;
    #feature{
      background: #FBF6EB;
      .slider-home{
        margin-bottom: 30px;
      }
      .slide-order{
        strong{
          font-weight: 300;
        }
        i{
          padding-right: .3rem;
        }
        @media (min-width: 920px){
          z-index: 1000;
          background: #FBF6EB;
          font-size: 2.4rem;
          padding:5px 20px;
          position: absolute;
          top: 120px;
          right:30px;
          box-shadow: 0 3px 3px rgba(0,0,0,.1);
          strong{
            font-size: 2.8rem;
          }
          i{
            font-size: 2.2rem;
          }
        }
        @media (max-width: 919px){
          font-size: 1.6rem;
          text-align: center;
          i{
            font-size: 1.4rem;
            margin-left: .5rem;
          }
          @media (min-width: 400px){
            padding:10px;
            br{
              display: none;
            }
          }
          @media (max-width: 399px){
            padding:0 0 5px;
            line-height: 1.3;
            strong{
              font-size: 1rem;
              font-weight: bold;
            }
          }
        }
      }
    }
    #topics{
      h2{
        font-size: 2rem;
      }
      margin-top: 5rem;
      iframe{
        border: 0;
      }
    }
    #location{
      &>div{
        position: relative;
      }
      h2{
        font-size: 2rem;
      }
      h3{
        margin-top: .5rem;
        font-size: 1.3rem;
        span{
          font-size: .9rem;
          padding-right: .4rem;
          &.address{
            display: block;
            // text-align: right;
            padding-top:.2rem;
            color: #666;
          }
        }
      }
      p{
        margin-bottom: 0;
        font-size: 1rem;
        padding: 0 .7rem;
        position: absolute;
        top:5px;
        left:5px;
        background: rgba(0,0,0,.5);
        transform: rotate(-6deg);
        color: #fff;
      }
      @media (min-width: 920px){
        a:hover{
          text-decoration: none;
          p{
            animation: fluffy 3s ease infinite;
            @keyframes fluffy {
              0% { transform:translateY(0) }
              10% { transform:translateY(0) }
              15% { transform:translateY(-10px) }
              20% { transform:translateY(0) }
              25% { transform:translateY(-10px) }
              45% { transform:translateY(0) }
              100% { transform:translateY(0) }
            }
          }
        }
        @media (max-width: 1200px){
          h3 span{
            display: block;
            font-size: .8rem;
            margin-bottom: .2rem;
          }
        }
      }
      @media (max-width: 919px){
        a{
          img{
            transition: all .2s linear;
          }
          &:hover{
            text-decoration: none;
            img{
              transform: scale(1.01);
            }
          }
        }
      }
    }
    #service{
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: all .2s linear;
        h2{
          font-size: 3rem;
          font-weight: 700;
          color: #fff;
          text-shadow: 0 2px 3px #111;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          letter-spacing: .2rem;
          span{
            font-size: 1.2rem;
            display: block;
            padding:10px;
            font-weight: 400;
          }
        }
        &:hover{
          // background-size:110% !important;
          // transform: scale(1.02);
          opacity: .8;
        }
        &#service-1{
          background: url('/_uploads/home-service-1.jpg') no-repeat center center/cover;
          height:15rem;
        }
        &#service-2{
          background: url('/_uploads/home-service-2.jpg') no-repeat center center/cover;
          height: 15rem;
        }
        &#service-3{
          background: url('/_uploads/home-service-3.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
        &#service-4{
          background: url('/_uploads/home-service-4.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
        &#service-5{
          background: url('/_uploads/home-service-5.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
      }
    }
    #instagram, #facebook{
      h2{
        font-size: 2.4rem;
      }
      @media (min-width: 920px){
        h2{
          margin-bottom: 1.3rem;
        }
      }
      @media (max-width: 919px){
        padding: 10px 0 0;
        h2{
          margin-bottom: .9rem;
        }
      }
      p{
        font-size: 1.2rem;
      }
      .btn{
        width: 100%;
        max-width: 500px;
      }
    }
    #instagram{
      .insta-photos a:before, .insta-photos div:before{
        content: "";
        display: block;
        padding-top: 100%;
      }
      .insta-photos a img, .insta-photos a video, .insta-photos div img, .insta-photos div video{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: bottom;';
      }
      .insta-photos > div, a{
        transition: all .2rem linear;
        &:hover{
          transform: scale(1.02);
        }
      }
    }
    h2{
      margin-bottom: 1.7rem;
      a{
        color: $color-base;
        text-decoration: none;
      }
    }
    .slick-dots{
      @media (max-width: 919px){
        bottom:-20px;
      }
      li{
        width:9px ;
        height: 9px;
        button{
          width:9px ;
          height: 9px;
          &:before{
            content:'　';
            background:$color-base;
            width:9px ;
            height: 9px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

/* ---------------------------
店舗案内
*/
.location {
  #contents{
    #headline{
      h1{
        span{
          display: inline-block;
          padding:0 .5rem;
        }
      }
    }
    #slider{
      height: 30vw
    }
    #tag{
      position: relative;
      z-index: 1000;
      margin-top: -36px;
      display: flex;
      justify-content: center;
      div{
        border: 4px solid #333;
        border-radius: 10px;
        font-size: 1.6rem;
        padding: 10px 1.1rem;
        background: #fff;
        margin:0 8px;
        font-weight: bold;
      }
      @media (max-width: 768px){
        flex-wrap: wrap;
        margin-top: -20px;
        div{
          border: 2px solid #333;
          font-size: 1rem;
          padding: 4px 8px;
          margin:0 2px 3px;
        }
      }
    }
    #information{
      .lead{
        font-size: 1rem;
        line-height: 2;
        font-weight: bold;
        @media (max-width: 520px){
          font-size: .9rem;
          line-height: 1.8;
        }
      }
      .copy{
        font-weight: bold;
      }
      // .gmap{
      //   position: relative;
      //   width: 100%;
      //   padding-top: 56.25%;
      //   iframe {
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }
    #streetview{
      margin:0 auto;
      position: relative;
      width: 100%;
      iframe {
        width: 100%;
        @media (min-width: 520px){
          max-height:440px;
        }
        @media (max-width: 519px){
          max-height:360px;
        }
      }
    }
    #menu{
      h2{
        font-size: 2.2rem;
        margin-bottom: 1rem;
        font-weight: bold;
        span{
          font-size: 70%;
        }
      }
      @media (max-width: 520px){
        h2{
          font-size: 1.8rem;
        }
        .copy{
          font-size: 1.1rem;
        }
      }
    }
    @media (max-width: 991px){
      #headline{
        h1{
          span{
            display: block;
            padding:0;
          }
        }
      }
      .block{
        .heading{
          h1{
            font-weight: normal;
            font-size: 1rem;
            margin-bottom: 15px;
          }
          p{
            color: #fff;
            margin-bottom: 0;
          }
        }
      }
    }
    @media (min-width: 992px){
      dl{
        dt{
          display: inline-block;
          width: 9rem;
          font-size: 1.2rem;
          vertical-align: top;
          padding-bottom: .5rem;
          margin-bottom: 0;
        }
        dd{
          display: inline-block;
          width: calc(100% - 10rem);
          vertical-align: top;
          padding-bottom: .5rem;
          margin-bottom: 0;
        }
      }
    }
    #osouzai section {
      h2{
        text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
        span{
          font-size: 1.3rem;
        }
      }
      .copy{
        line-height: 1.6;
      }
      p {
        line-height: 1.8;
        letter-spacing: 0.1rem;
        font-size: .95rem;
        span{
          font-size: 80%;
          padding-left: .5rem;
          color: #666;
        }
      }
      .kind{
        text-align: center;
        p{
          font-size: 1.5rem;
          text-align: left;
        }
      }
    }
  }
}
.location-1{
  #contents{
    // #slider img {
    //   width: 100%;
    //   height: 40vw;
    //   object-fit: cover;
    // }
    #information{
      p.lead{
        color: #fff;
      }
      dl{
        border-color: #ccc;
        dd a{
          color: #ccc;
        }
      }
    }
    #service{
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: all .2s linear;
        h2{
          font-size: 3rem;
          font-weight: 700;
          color: #fff;
          text-shadow: 0 2px 3px #111;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          letter-spacing: .2rem;
        }
        &:hover{
          // background-size:110% !important;
          // transform: scale(1.02);
          opacity: .8;
        }
        &#service-2{
          background: url('/_uploads/home-service-2.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
        &#service-3{
          background: url('/_uploads/home-service-3.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
        &#service-4{
          background: url('/_uploads/home-service-4.jpg') no-repeat center center/cover;
          height: 14rem;
          h2{
            line-height: 1.3;
          }
        }
      }
    }
  }
}

/* ---------------------------
お料理
*/
.service {
  #headline {
    .lead {
      letter-spacing: 0.1rem;
    }
    .deriver{
      font-size: 2.2rem;
      font-weight: bold;
      text-align: center;
      span{
        font-size: 1.5rem;
      }
    }
    @media (max-width: 767px){
      .copy {
        font-size: 1.3rem;
      }
      .deriver{
        font-size: 1.6rem;
      }
    }
  }
  #contents section {
    h2{
      text-align: center;
      font-size: 1.8rem;
      font-weight: bold;
      span{
        font-size: 1.3rem;
      }
    }
    .copy{
      line-height: 1.6;
      @media (max-width: 767px){
        font-size: 1.1rem;
      }
    }
    p {
      line-height: 1.6;
      letter-spacing: 0.1rem;
    }
    .kind{
      text-align: center;
      p{
        font-size: 1.5rem;
        text-align: left;
      }
    }
  }
  .slider{
    img{
      height: calc(100vw - 40%);
      display: block;
      @media (max-width: 768px){
        height: calc(100vw - 10%);
      }
    }
  }
  #contents{
    #locations{
      a{
        transition: all .2s;
      }
      a:hover{
        text-decoration: none;
        opacity: .8;
      }
      h2{
        font-size: 1.5rem;
        font-weight: 500;
      }
      @media (max-width: 767px){
        h2{
          font-size: 1.1rem;
          line-height: 1.5;
        }
        h3{
          font-size: 1rem;
        }
      }
      h3{
        margin-top: .5rem;
        font-size: 1.3rem;
        span{
          font-size: .9rem;
          padding-right: .4rem;
          &.address{
            display: block;
            // text-align: right;
            padding-top:.2rem;
            color: #666;
          }
        }
      }
    }

    #information{
      .btn{
        cursor: auto;
        border-color: $color-red;
        color: #fff;
        background: $color-red;
        strong{
          font-size: 2.2rem;
        }
        &:hover{
          color: $color-red;
          background: #fff;
        }
      }
    }
  }
}
.service-1{
  #contents{
    p.lead span{
      font-size: 80%;
      padding-left: .5rem;
      color: #666;
    }
  }
}
.service-2{
  #contents  #kind{
    p{
      font-size: 1.2rem
    }
  }
}
// .service-4{
//   #information{
//     dl{
//       border-top: 2px solid #222;
//       border-bottom: 2px solid #222;
//       padding: 1.4rem 0 .6rem;
//       dt{
//         font-size: 1.2rem;
//         display: block;
//       }
//       dd{
//         font-size: 1.2rem;
//         a{
//           text-decoration: underline;
//           font-size: .95rem;
//           display: inline-block;
//         }
//       }
//     }
//   }
// }

/* ---------------------------
会社案内
*/
.about{
  #contents{
    h1{
      padding:34px 0;
      font-size: 2.4rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
      @media (max-width: 767px){
        padding:24px 0;
        font-size: 2rem;
      }
      @media (max-width: 520px){
        padding:14px 0;
        font-size: 1.6rem;
      }
    }
    #message{
      p{
        font-size: 1.1rem;
        font-weight: bold;
        img{
          width:270px;
          margin:15px 0 5px;
        }
      }
    }
    #outline{
      table{
        border-top: 1px solid #ddd;
        tr{
          border-bottom: 1px solid #ddd;
        }
        th,td{
          border:0;
          padding: .75rem .2rem;
        }
        th{
          width: 8rem;
        }
        @media (max-width: 767px){
          th,td{
            font-size: 1rem;
          }
          th{
            width: 6rem;
          }
        }
      }
    }
    @media (max-width: 767px){
      .copy{
        font-size: 1rem;
      }
    }
    @media (max-width: 520px){
      #message{
        p{
          img{
            margin: 0 0 3px;
          }
        }
      }
    }
  }
}



/* ---------------------------
ご注文・ご予約
*/
.order{
  #contents{
    table{
      border-top: 1px solid #ddd;
      tr{
        border-bottom: 1px solid #ddd;
      }
      th,td{
        border:0;
        padding: .75rem .2rem;
      }
      th{
        width: 10rem;
      }
      td{
        a{
          text-decoration: underline;
        }
      }
      @media (max-width: 767px){
        th,td{
          font-size: 1rem;
        }
        th{
          width: 8rem;
        }
      }
    }
    @media (max-width: 767px){
      .copy{
        font-size: 1rem;
      }
    }
  }
}
