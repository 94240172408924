@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */
body {
  margin: 0;
  padding: 0;
  color: #444;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 400;
  background: #FBF6EB; }
  @media (max-width: 919px) {
    body.sp-menu-open {
      overflow: hidden; } }

h1, h2 {
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-feature-settings: "palt"; }

h3, h4 {
  font-weight: 400; }

table {
  margin: 1em 0;
  width: 100%;
  font-size: 110%; }

th, td {
  vertical-align: top;
  padding: 0.4em;
  border: 1px dotted #5D3F37; }

th {
  text-align: left; }

td ol, td ul {
  margin: 0; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 1em; }

a {
  color: #444; }
  a:hover {
    color: #444; }

.btn-primary {
  background: #444;
  border-color: #444;
  padding: .6rem 1.2rem;
  font-size: .9rem;
  border-radius: 8px;
  font-weight: normal;
  transition: 0.5s; }
  .btn-primary:hover {
    background: #821428;
    border-color: #821428; }

.kerning {
  font-feature-settings: "palt"; }

/* ---------------------------
header
*/
header p {
  font-size: .8rem;
  padding-top: .4rem;
  margin-bottom: 0; }

header .container {
  position: relative;
  background: url("/_assets/img/character.png") no-repeat 15px 3px;
  padding-left: 95px; }

header .title {
  margin-bottom: 14px; }
  header .title a {
    color: #444; }
    header .title a:hover {
      text-decoration: none; }

@media (min-width: 992px) {
  header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1200;
    background: rgba(255, 255, 255, 0.95); }
    header .container {
      height: 87px;
      max-width: 1140px; }
    header .header-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 5px; }
    header .title {
      margin-bottom: 0; }
      header .title a {
        font-size: 1.6rem;
        font-weight: normal; }
      header .title img {
        width: 200px; }
    header ul {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      font-feature-settings: "palt"; }
      header ul li {
        padding: 1rem .4rem 0 .5rem; }
        header ul li a, header ul li > span {
          padding: .2rem 0;
          text-decoration: none;
          display: block;
          transition: 0.2s;
          cursor: pointer;
          font-weight: bold; }
          header ul li a:hover, header ul li > span:hover {
            text-decoration: none;
            color: #666; }
        header ul li#nav-location > span {
          vertical-align: center; }
          header ul li#nav-location > span::after {
            font-family: "Font Awesome 5 Free";
            padding-left: .3rem;
            content: '\f0d7';
            font-weight: 900; }
        header ul li#nav-order {
          position: absolute;
          top: -3px;
          right: 15px; }
          header ul li#nav-order a {
            background: #D21E22;
            border-radius: 10px;
            color: #fff;
            border-bottom: 0;
            padding: 2px 10px;
            border: 2px solid #D21E22; }
            header ul li#nav-order a:hover {
              background: #fff;
              color: #D21E22; }
        header ul li .pulldown {
          display: none;
          background: #fff;
          position: absolute;
          z-index: 9000 !important;
          box-sizing: border-box;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          width: 31rem;
          max-width: 800px;
          margin: 7px auto 0; }
          header ul li .pulldown ul {
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;
            border: 0;
            display: block; }
            header ul li .pulldown ul li {
              color: #000;
              padding: 0.2em 0 0;
              line-height: 1.4;
              display: block;
              text-align: left; }
              header ul li .pulldown ul li a {
                display: block;
                padding: .5rem;
                color: #444; }
                header ul li .pulldown ul li a:hover {
                  color: #444;
                  border-bottom-color: transparent;
                  background: #eee; }
                header ul li .pulldown ul li a strong {
                  font-size: 1rem;
                  padding-left: .2rem; }
                header ul li .pulldown ul li a span {
                  font-size: .8rem;
                  padding-left: .4rem;
                  display: inline-block; }
        header ul li .pulldown.spread {
          display: block; }
    header .overlay, header .menu-trigger {
      display: none; } }
  @media (min-width: 992px) and (max-width: 1050px) {
    header nav > ul > li {
      font-size: .85rem;
      padding: 1rem .3rem 0; } }

@media (max-width: 991px) {
  header {
    background: rgba(255, 255, 255, 0.6);
    position: relative; }
    header .container {
      max-width: none;
      background-size: 65px;
      padding-left: 90px; }
    header .title {
      padding: 0 0 5px;
      z-index: -1;
      margin-bottom: 0; }
      header .title .logo {
        font-size: 2rem; }
      header .title div {
        font-size: .7rem; }
        header .title div strong {
          font-size: 1rem; }
    header .menu-trigger {
      display: inline-block;
      width: 28px;
      height: 34px;
      vertical-align: middle;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 18px;
      z-index: 2002;
      transform: translateX(0); }
      header .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #444;
        transition: all .5s; }
        header .menu-trigger span:nth-of-type(1) {
          top: 3px; }
        header .menu-trigger span:nth-of-type(2) {
          top: 9px; }
        header .menu-trigger span:nth-of-type(3) {
          top: 15px; }
      header .menu-trigger div {
        font-size: 10px;
        text-align: center;
        bottom: 0;
        position: absolute;
        letter-spacing: -.04rem; }
      header .menu-trigger.active {
        transform: translateX(0);
        z-index: 3000; }
        header .menu-trigger.active span {
          background-color: #444 !important; }
          header .menu-trigger.active span:nth-of-type(1) {
            transform: translateY(6px) rotate(-45deg); }
          header .menu-trigger.active span:nth-of-type(2) {
            opacity: 0; }
          header .menu-trigger.active span:nth-of-type(3) {
            transform: translateY(-6px) rotate(45deg); }
    header nav {
      display: none;
      width: 100vw;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1600;
      transform: translate(-100vw);
      transition: all .5s;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      padding: 65px 8px 0; }
      header nav ul {
        margin: 0 0 5px;
        padding-left: 0; }
        header nav ul li a {
          color: #444;
          display: block;
          text-decoration: none;
          padding: .5rem;
          border-bottom: 1px solid transparent; }
          header nav ul li a:hover {
            text-decoration: none;
            color: #000; }
        header nav ul li > span {
          padding: .5rem .6rem .2rem;
          display: block; }
        header nav ul li ul {
          margin: 0 0 .1rem; }
          header nav ul li ul li {
            line-height: 1.4; }
            header nav ul li ul li a {
              padding: .4rem 1.2rem; }
            header nav ul li ul li span {
              font-size: .8rem; }
            header nav ul li ul li strong {
              padding: 0 .4rem 0 .2rem; }
              header nav ul li ul li strong + span {
                background: #eee;
                padding: .1rem .2rem; }
        header nav ul li#nav-order a {
          background: #D21E22;
          border-radius: 10px;
          color: #fff;
          border-bottom: 0;
          padding: 2px 10px;
          border: 2px solid #D21E22;
          display: inline-block;
          margin-top: .2rem; }
          header nav ul li#nav-order a:hover {
            background: #fff;
            color: #D21E22; }
      header nav.open {
        display: block;
        transform: translateX(0);
        overflow: hidden; }
        header nav.open ul {
          box-sizing: border-box; }
          header nav.open ul li {
            list-style: none; }
    header .overlay {
      content: "";
      display: block;
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .5s;
      top: 0;
      z-index: 0; }
      header .overlay.open {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1001; } }

@media (max-width: 620px) {
  header p {
    font-size: .6rem;
    padding: 5px 0 5px 10px; }
  header .container {
    background-size: 45px;
    padding-left: 0;
    background-position: 10px 25px; }
    header .container .title .logo {
      font-size: 2rem; }
      header .container .title .logo img {
        width: 160px; }
    header .container .header-wrap {
      padding-left: 60px; }
  header nav {
    padding: 80px 8px 0; }
  header .menu-trigger {
    top: 37px; } }

@media (max-width: 374px) {
  header .container {
    background-position: 5px 40px; }
  header .menu-trigger {
    top: 50px; } }

/* ---------------------------
footer
*/
footer {
  text-align: center;
  color: #666; }
  footer #footer-wrap {
    padding: 20px 0 40px;
    position: relative; }
    footer #footer-wrap .copyright {
      opacity: 0.6;
      font-size: 1rem;
      margin-top: 1rem; }
    @media (max-width: 767px) {
      footer #footer-wrap {
        padding-bottom: 25px; }
        footer #footer-wrap .copyright {
          font-size: .8rem; } }

#page-top {
  position: fixed;
  display: none !important;
  right: 10px; }
  @media (min-width: 768px) {
    #page-top {
      bottom: 20px;
      z-index: 3000; } }
  @media (max-width: 767px) {
    #page-top {
      bottom: 10px; } }
  #page-top a {
    display: block;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    color: #444;
    text-decoration: none;
    font-size: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
    #page-top a span {
      display: block; }

/* ---------------------------
contents
*/
#contents h1 {
  font-size: 1.8rem;
  margin-bottom: 20px; }
  #contents h1 span {
    font-size: 70%; }

#contents p {
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.1rem; }

#contents p.lead {
  color: #333;
  line-height: 1.5; }

#contents h2 {
  font-size: 1.3rem;
  margin-top: 0.4rem; }

#contents h3 {
  font-size: 1.2rem;
  font-feature-settings: "palt"; }

@media (min-width: 992px) {
  #contents {
    padding: 87px 0 30px; } }

@media (max-width: 767px) {
  #contents h2 {
    font-size: 1.4rem; } }

#contents img {
  display: inline-block; }

#contents .content-nav a {
  background: #f9f9f9;
  padding: .5rem;
  border-radius: 3px;
  display: block;
  margin-bottom: 1rem; }
  #contents .content-nav a:hover {
    text-decoration: none;
    background: #f5f5f5; }

#contents .copy {
  font-size: 1.4rem; }

#contents .btn-primary {
  font-weight: 300; }

#contents .btn {
  font-size: 1.2rem;
  font-weight: 300;
  padding: .75rem 2rem;
  border: 2px solid #444; }
  #contents .btn:hover {
    background: #fff;
    color: #444; }

#contents #information dl {
  border-top: 2px solid #222;
  border-bottom: 2px solid #222;
  padding: 1.4rem 0 .6rem; }
  #contents #information dl dt {
    font-size: 1.2rem; }
  #contents #information dl dd {
    font-size: 1.2rem;
    margin-bottom: .8rem; }
    #contents #information dl dd a {
      text-decoration: underline;
      font-size: .95rem;
      display: inline-block; }
  @media (min-width: 768px) {
    #contents #information dl dt {
      display: inline-block;
      width: 9rem;
      vertical-align: top;
      padding-bottom: .5rem;
      margin-bottom: 0; }
    #contents #information dl dd {
      display: inline-block;
      width: calc(100% - 10rem);
      vertical-align: top;
      padding-bottom: .5rem;
      margin-bottom: 0; } }
  @media (max-width: 520px) {
    #contents #information dl dt {
      font-size: 1rem; }
    #contents #information dl dd {
      font-size: 1rem; } }

.dark #contents {
  background: #320505 url("/_uploads/dark-bg.png") repeat-x top center;
  color: #fff; }
  .dark #contents a {
    color: #fff !important; }
    .dark #contents a:hover {
      color: #fff; }
  @media (min-width: 992px) {
    .dark #contents {
      padding-top: 0;
      margin-top: 87px; } }
  .dark #contents .text-red {
    color: #f66;
    font-size: 1.5rem;
    font-weight: bold; }
  .dark #contents #information p.lead {
    color: #fff; }
  .dark #contents #information dl {
    border-color: #ccc; }
    .dark #contents #information dl dd a {
      color: #ccc; }

#headline .directory {
  display: none;
  background: url("/_uploads/header-bg.jpg") no-repeat center center/cover;
  font-size: 32px;
  color: #fff;
  text-align: center;
  padding: 40px;
  letter-spacing: .1rem; }
  #headline .directory span {
    font-size: 24px;
    padding: .1rem; }

#headline h1 {
  padding: 34px 0;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0; }
  #headline h1 a {
    color: #444;
    text-decoration: none; }

#headline .localnav {
  margin-bottom: 30px; }
  #headline .localnav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0; }
    #headline .localnav ul li {
      list-style: none;
      padding: 20px 10px; }
      #headline .localnav ul li a {
        padding: .7rem 1.4rem;
        background: #fff;
        border-radius: .8rem;
        border: 3px solid #D07171; }
        #headline .localnav ul li a:hover {
          text-decoration: none;
          background: #ffc; }
      @media (max-width: 767px) {
        #headline .localnav ul li {
          font-size: .8rem;
          padding: 20px 5px 10px; }
          #headline .localnav ul li a {
            padding: .5rem 1rem; } }

@media (max-width: 767px) {
  #headline h1 {
    padding: 24px 0;
    font-size: 44px; } }

@media (max-width: 520px) {
  #headline h1 {
    padding: 20px 0;
    font-size: 34px; }
    #headline h1 span {
      font-size: 50%; } }

/* ---------------------------
ホーム
*/
.home header .title {
  display: block; }
  .home header .title h1 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 2rem; }

.home #contents {
  z-index: 1000 !important;
  position: relative; }
  .home #contents #feature {
    background: #FBF6EB; }
    .home #contents #feature .slider-home {
      margin-bottom: 30px; }
    .home #contents #feature .slide-order strong {
      font-weight: 300; }
    .home #contents #feature .slide-order i {
      padding-right: .3rem; }
    @media (min-width: 920px) {
      .home #contents #feature .slide-order {
        z-index: 1000;
        background: #FBF6EB;
        font-size: 2.4rem;
        padding: 5px 20px;
        position: absolute;
        top: 120px;
        right: 30px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); }
        .home #contents #feature .slide-order strong {
          font-size: 2.8rem; }
        .home #contents #feature .slide-order i {
          font-size: 2.2rem; } }
    @media (max-width: 919px) {
      .home #contents #feature .slide-order {
        font-size: 1.6rem;
        text-align: center; }
        .home #contents #feature .slide-order i {
          font-size: 1.4rem;
          margin-left: .5rem; } }
  @media (max-width: 919px) and (min-width: 400px) {
    .home #contents #feature .slide-order {
      padding: 10px; }
      .home #contents #feature .slide-order br {
        display: none; } }
  @media (max-width: 919px) and (max-width: 399px) {
    .home #contents #feature .slide-order {
      padding: 0 0 5px;
      line-height: 1.3; }
      .home #contents #feature .slide-order strong {
        font-size: 1rem;
        font-weight: bold; } }
  .home #contents #topics {
    margin-top: 5rem; }
    .home #contents #topics h2 {
      font-size: 2rem; }
    .home #contents #topics iframe {
      border: 0; }
  .home #contents #location > div {
    position: relative; }
  .home #contents #location h2 {
    font-size: 2rem; }
  .home #contents #location h3 {
    margin-top: .5rem;
    font-size: 1.3rem; }
    .home #contents #location h3 span {
      font-size: .9rem;
      padding-right: .4rem; }
      .home #contents #location h3 span.address {
        display: block;
        padding-top: .2rem;
        color: #666; }
  .home #contents #location p {
    margin-bottom: 0;
    font-size: 1rem;
    padding: 0 .7rem;
    position: absolute;
    top: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.5);
    transform: rotate(-6deg);
    color: #fff; }
  @media (min-width: 920px) {
    .home #contents #location a:hover {
      text-decoration: none; }
      .home #contents #location a:hover p {
        animation: fluffy 3s ease infinite; }
    @keyframes fluffy {
      0% {
        transform: translateY(0); }
      10% {
        transform: translateY(0); }
      15% {
        transform: translateY(-10px); }
      20% {
        transform: translateY(0); }
      25% {
        transform: translateY(-10px); }
      45% {
        transform: translateY(0); }
      100% {
        transform: translateY(0); } } }

@media (min-width: 920px) and (max-width: 1200px) {
  .home #contents #location h3 span {
    display: block;
    font-size: .8rem;
    margin-bottom: .2rem; } }
  @media (max-width: 919px) {
    .home #contents #location a img {
      transition: all .2s linear; }
    .home #contents #location a:hover {
      text-decoration: none; }
      .home #contents #location a:hover img {
        transform: scale(1.01); } }
  .home #contents #service a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all .2s linear; }
    .home #contents #service a h2 {
      font-size: 3rem;
      font-weight: 700;
      color: #fff;
      text-shadow: 0 2px 3px #111;
      text-align: center;
      line-height: 1.4;
      margin: 0;
      letter-spacing: .2rem; }
      .home #contents #service a h2 span {
        font-size: 1.2rem;
        display: block;
        padding: 10px;
        font-weight: 400; }
    .home #contents #service a:hover {
      opacity: .8; }
    .home #contents #service a#service-1 {
      background: url("/_uploads/home-service-1.jpg") no-repeat center center/cover;
      height: 15rem; }
    .home #contents #service a#service-2 {
      background: url("/_uploads/home-service-2.jpg") no-repeat center center/cover;
      height: 15rem; }
    .home #contents #service a#service-3 {
      background: url("/_uploads/home-service-3.jpg") no-repeat center center/cover;
      height: 14rem; }
      .home #contents #service a#service-3 h2 {
        line-height: 1.3; }
    .home #contents #service a#service-4 {
      background: url("/_uploads/home-service-4.jpg") no-repeat center center/cover;
      height: 14rem; }
      .home #contents #service a#service-4 h2 {
        line-height: 1.3; }
    .home #contents #service a#service-5 {
      background: url("/_uploads/home-service-5.jpg") no-repeat center center/cover;
      height: 14rem; }
      .home #contents #service a#service-5 h2 {
        line-height: 1.3; }
  .home #contents #instagram h2, .home #contents #facebook h2 {
    font-size: 2.4rem; }
  @media (min-width: 920px) {
    .home #contents #instagram h2, .home #contents #facebook h2 {
      margin-bottom: 1.3rem; } }
  @media (max-width: 919px) {
    .home #contents #instagram, .home #contents #facebook {
      padding: 10px 0 0; }
      .home #contents #instagram h2, .home #contents #facebook h2 {
        margin-bottom: .9rem; } }
  .home #contents #instagram p, .home #contents #facebook p {
    font-size: 1.2rem; }
  .home #contents #instagram .btn, .home #contents #facebook .btn {
    width: 100%;
    max-width: 500px; }
  .home #contents #instagram .insta-photos a:before, .home #contents #instagram .insta-photos div:before {
    content: "";
    display: block;
    padding-top: 100%; }
  .home #contents #instagram .insta-photos a img, .home #contents #instagram .insta-photos a video, .home #contents #instagram .insta-photos div img, .home #contents #instagram .insta-photos div video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: bottom;'; }
  .home #contents #instagram .insta-photos > div, .home #contents #instagram a {
    transition: all .2rem linear; }
    .home #contents #instagram .insta-photos > div:hover, .home #contents #instagram a:hover {
      transform: scale(1.02); }
  .home #contents h2 {
    margin-bottom: 1.7rem; }
    .home #contents h2 a {
      color: #444;
      text-decoration: none; }
  @media (max-width: 919px) {
    .home #contents .slick-dots {
      bottom: -20px; } }
  .home #contents .slick-dots li {
    width: 9px;
    height: 9px; }
    .home #contents .slick-dots li button {
      width: 9px;
      height: 9px; }
      .home #contents .slick-dots li button:before {
        content: '　';
        background: #444;
        width: 9px;
        height: 9px;
        border-radius: 50%; }

/* ---------------------------
店舗案内
*/
.location #contents #headline h1 span {
  display: inline-block;
  padding: 0 .5rem; }

.location #contents #slider {
  height: 30vw; }

.location #contents #tag {
  position: relative;
  z-index: 1000;
  margin-top: -36px;
  display: flex;
  justify-content: center; }
  .location #contents #tag div {
    border: 4px solid #333;
    border-radius: 10px;
    font-size: 1.6rem;
    padding: 10px 1.1rem;
    background: #fff;
    margin: 0 8px;
    font-weight: bold; }
  @media (max-width: 768px) {
    .location #contents #tag {
      flex-wrap: wrap;
      margin-top: -20px; }
      .location #contents #tag div {
        border: 2px solid #333;
        font-size: 1rem;
        padding: 4px 8px;
        margin: 0 2px 3px; } }

.location #contents #information .lead {
  font-size: 1rem;
  line-height: 2;
  font-weight: bold; }
  @media (max-width: 520px) {
    .location #contents #information .lead {
      font-size: .9rem;
      line-height: 1.8; } }

.location #contents #information .copy {
  font-weight: bold; }

.location #contents #streetview {
  margin: 0 auto;
  position: relative;
  width: 100%; }
  .location #contents #streetview iframe {
    width: 100%; }
    @media (min-width: 520px) {
      .location #contents #streetview iframe {
        max-height: 440px; } }
    @media (max-width: 519px) {
      .location #contents #streetview iframe {
        max-height: 360px; } }

.location #contents #menu h2 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-weight: bold; }
  .location #contents #menu h2 span {
    font-size: 70%; }

@media (max-width: 520px) {
  .location #contents #menu h2 {
    font-size: 1.8rem; }
  .location #contents #menu .copy {
    font-size: 1.1rem; } }

@media (max-width: 991px) {
  .location #contents #headline h1 span {
    display: block;
    padding: 0; }
  .location #contents .block .heading h1 {
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 15px; }
  .location #contents .block .heading p {
    color: #fff;
    margin-bottom: 0; } }

@media (min-width: 992px) {
  .location #contents dl dt {
    display: inline-block;
    width: 9rem;
    font-size: 1.2rem;
    vertical-align: top;
    padding-bottom: .5rem;
    margin-bottom: 0; }
  .location #contents dl dd {
    display: inline-block;
    width: calc(100% - 10rem);
    vertical-align: top;
    padding-bottom: .5rem;
    margin-bottom: 0; } }

.location #contents #osouzai section h2 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold; }
  .location #contents #osouzai section h2 span {
    font-size: 1.3rem; }

.location #contents #osouzai section .copy {
  line-height: 1.6; }

.location #contents #osouzai section p {
  line-height: 1.8;
  letter-spacing: 0.1rem;
  font-size: .95rem; }
  .location #contents #osouzai section p span {
    font-size: 80%;
    padding-left: .5rem;
    color: #666; }

.location #contents #osouzai section .kind {
  text-align: center; }
  .location #contents #osouzai section .kind p {
    font-size: 1.5rem;
    text-align: left; }

.location-1 #contents #information p.lead {
  color: #fff; }

.location-1 #contents #information dl {
  border-color: #ccc; }
  .location-1 #contents #information dl dd a {
    color: #ccc; }

.location-1 #contents #service a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all .2s linear; }
  .location-1 #contents #service a h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 0 2px 3px #111;
    text-align: center;
    line-height: 1.4;
    margin: 0;
    letter-spacing: .2rem; }
  .location-1 #contents #service a:hover {
    opacity: .8; }
  .location-1 #contents #service a#service-2 {
    background: url("/_uploads/home-service-2.jpg") no-repeat center center/cover;
    height: 14rem; }
    .location-1 #contents #service a#service-2 h2 {
      line-height: 1.3; }
  .location-1 #contents #service a#service-3 {
    background: url("/_uploads/home-service-3.jpg") no-repeat center center/cover;
    height: 14rem; }
    .location-1 #contents #service a#service-3 h2 {
      line-height: 1.3; }
  .location-1 #contents #service a#service-4 {
    background: url("/_uploads/home-service-4.jpg") no-repeat center center/cover;
    height: 14rem; }
    .location-1 #contents #service a#service-4 h2 {
      line-height: 1.3; }

/* ---------------------------
お料理
*/
.service #headline .lead {
  letter-spacing: 0.1rem; }

.service #headline .deriver {
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center; }
  .service #headline .deriver span {
    font-size: 1.5rem; }

@media (max-width: 767px) {
  .service #headline .copy {
    font-size: 1.3rem; }
  .service #headline .deriver {
    font-size: 1.6rem; } }

.service #contents section h2 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold; }
  .service #contents section h2 span {
    font-size: 1.3rem; }

.service #contents section .copy {
  line-height: 1.6; }
  @media (max-width: 767px) {
    .service #contents section .copy {
      font-size: 1.1rem; } }

.service #contents section p {
  line-height: 1.6;
  letter-spacing: 0.1rem; }

.service #contents section .kind {
  text-align: center; }
  .service #contents section .kind p {
    font-size: 1.5rem;
    text-align: left; }

.service .slider img {
  height: calc(100vw - 40%);
  display: block; }
  @media (max-width: 768px) {
    .service .slider img {
      height: calc(100vw - 10%); } }

.service #contents #locations a {
  transition: all .2s; }

.service #contents #locations a:hover {
  text-decoration: none;
  opacity: .8; }

.service #contents #locations h2 {
  font-size: 1.5rem;
  font-weight: 500; }

@media (max-width: 767px) {
  .service #contents #locations h2 {
    font-size: 1.1rem;
    line-height: 1.5; }
  .service #contents #locations h3 {
    font-size: 1rem; } }

.service #contents #locations h3 {
  margin-top: .5rem;
  font-size: 1.3rem; }
  .service #contents #locations h3 span {
    font-size: .9rem;
    padding-right: .4rem; }
    .service #contents #locations h3 span.address {
      display: block;
      padding-top: .2rem;
      color: #666; }

.service #contents #information .btn {
  cursor: auto;
  border-color: #D21E22;
  color: #fff;
  background: #D21E22; }
  .service #contents #information .btn strong {
    font-size: 2.2rem; }
  .service #contents #information .btn:hover {
    color: #D21E22;
    background: #fff; }

.service-1 #contents p.lead span {
  font-size: 80%;
  padding-left: .5rem;
  color: #666; }

.service-2 #contents #kind p {
  font-size: 1.2rem; }

/* ---------------------------
会社案内
*/
.about #contents h1 {
  padding: 34px 0;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .about #contents h1 {
      padding: 24px 0;
      font-size: 2rem; } }
  @media (max-width: 520px) {
    .about #contents h1 {
      padding: 14px 0;
      font-size: 1.6rem; } }

.about #contents #message p {
  font-size: 1.1rem;
  font-weight: bold; }
  .about #contents #message p img {
    width: 270px;
    margin: 15px 0 5px; }

.about #contents #outline table {
  border-top: 1px solid #ddd; }
  .about #contents #outline table tr {
    border-bottom: 1px solid #ddd; }
  .about #contents #outline table th, .about #contents #outline table td {
    border: 0;
    padding: .75rem .2rem; }
  .about #contents #outline table th {
    width: 8rem; }
  @media (max-width: 767px) {
    .about #contents #outline table th, .about #contents #outline table td {
      font-size: 1rem; }
    .about #contents #outline table th {
      width: 6rem; } }

@media (max-width: 767px) {
  .about #contents .copy {
    font-size: 1rem; } }

@media (max-width: 520px) {
  .about #contents #message p img {
    margin: 0 0 3px; } }

/* ---------------------------
ご注文・ご予約
*/
.order #contents table {
  border-top: 1px solid #ddd; }
  .order #contents table tr {
    border-bottom: 1px solid #ddd; }
  .order #contents table th, .order #contents table td {
    border: 0;
    padding: .75rem .2rem; }
  .order #contents table th {
    width: 10rem; }
  .order #contents table td a {
    text-decoration: underline; }
  @media (max-width: 767px) {
    .order #contents table th, .order #contents table td {
      font-size: 1rem; }
    .order #contents table th {
      width: 8rem; } }

@media (max-width: 767px) {
  .order #contents .copy {
    font-size: 1rem; } }
